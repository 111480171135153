.food {
  max-width: 1200px;
  width: 96%;
  margin: 2rem auto;
  animation: food-appear 1s ease-out forwards;
}

.food ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@keyframes food-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
