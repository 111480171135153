.food {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
  align-items: start;
}

.food h3 {
  margin: 0 0 0.25rem 0;
}

.foodInt {
  display: flex;
  gap: 20px;
  margin: 1rem;
  align-items: start;
  position: relative;
}

.foodBottom {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
}

.title {
  font-weight: bold;
  font-size: 21px;
}

.description {
  font-style: italic;
  font-size: 15px;
}

.price {
  margin-top: 0.25rem;
  font-weight: bold;
  color: #ad5502;
  font-size: 1.25rem;
}

.img {
  width: 100%;
}

.imgContainer {
  width: 25%;
  max-width: 150px;
  padding: 0;
  margin: 0;
  flex: 1;
  border: 1px solid #ad5502;
  font-size: 1px;
}

@media only screen and (max-width: 768px) {
  .foodInt {
    display: flex;
    width: 100%;
    gap: 10px;
    margin: 10px;
    align-items: start;
    position: relative;
  }

  .foodBottom {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    height:auto;
  }

  .imgContainer {
    width: 25%;
    max-width: 150px;
    padding: 0;
    margin: 0;
    border: 1px solid #ad5502;
    font-size: 1px;
  }

  .title {
    font-weight: bold;
    font-size: 15px;
  }

  .description {
    font-style: italic;
    font-size: 12px;
  }
}
