.header {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  padding: 0;
  height: 5rem;
  background-color: #8a2b06;
  color: white;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
  align-items: center;
}

.header_text {
  padding: 8px 4px !important;
  margin: -4px !important;
}

.header-container {
  width: 90%;
  padding: 20px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.hero {
  width: 100%;
  height: 25rem;
  margin-top: 80px;
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.hero img {
  width: 110%;
  height: 100%;
  object-fit: cover;
}

.logo {
  height: 46px;
}

.logoContainer {
  align-items: center;
  display: flex;
  gap:50px
}

.overText {
  width: 360px;
  max-width: 92%;
  height: 150px;
  margin: 0 auto;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  padding: 20px;
  color: #fff;
  text-align: center;
}

.menu {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}
